<template>
  <div
    class="row justify-center items-start bg-grey-3"
    style=" min-height: calc(100vh - 82px)"
  >
    <q-card flat bordered style="width:300px;">
      <q-card-section>
        <q-input
          outlined
          readonly
          label="username"
          :value="user.username"
        ></q-input>
      </q-card-section>
      <q-card-section class="column q-col-gutter-sm">
        <q-input
          outlined
          stack-label
          :type="isPwd_a ? 'password' : 'text'"
          ref="lama"
          label="Password Lama"
          v-model="pass.curr"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd_a ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd_a = !isPwd_a"
            />
          </template>
        </q-input>
        <q-input
          outlined
          stack-label
          :type="isPwd_b ? 'password' : 'text'"
          label="Password Baru"
          v-model="pass.new"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd_b ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd_b = !isPwd_b"
            />
          </template>
        </q-input>
        <q-input
          outlined
          stack-label
          :type="isPwd_c ? 'password' : 'text'"
          ref="retype"
          label="Ketik Ulang Password Baru"
          v-model="pass.confirm"
          :disable="pass.new == ''"
        >
          <template v-slot:append>
            <q-icon
              :name="isPwd_c ? 'visibility_off' : 'visibility'"
              class="cursor-pointer"
              @click="isPwd_c = !isPwd_c"
            />
          </template>
        </q-input>
      </q-card-section>
      <q-card-actions align="stretch">
        <q-btn
          flat
          class="full-width q-mb-sm q-mt-lg"
          label="back"
          color="primary"
          @click="$router.go(-1)"
        ></q-btn>
        <q-btn
          unelevated
          class="full-width"
          color="negative"
          label="Edit"
          :disable="pass.new == ''"
          @click="edit"
        ></q-btn>
      </q-card-actions>
    </q-card>
  </div>
</template>

<script>
import General from "@/mixins/General";
export default {
  mixins: [General],
  data() {
    return {
      isPwd_a: true,
      isPwd_b: true,
      isPwd_c: true,

      user: {
        username: "",
      },
      pass: {
        curr: "",
        new: "",
        confirm: "",
      },
    };
  },
  async mounted() {
    this.$q.loading.show({ message: "Mohon Tunggu..." });
    await this.getUser();
    this.$q.loading.hide();
  },
  methods: {
    async getUser() {
      let resp = await this.$http.get("/user_data", this.MGetHeader());

      let user = await this.$http.get(
        `/pengaturan/tahun_ajaran/getdata/${resp.data.id}`,
        this.$hlp.getHeader()
      );
      this.user = user.data;
    },
    async edit() {
      var md5 = require("md5");
      if (md5(this.pass.curr) != this.user.password) {
        this.$q.notify({
          message: "Password Lama Tidak Sesuai",
          color: "negative",
        });
        this.$refs.lama.focus();
      } else if (this.pass.new != this.pass.confirm) {
        this.$q.notify({
          message: "Password Baru Tidak Sama",
          color: "negative",
        });
        this.$refs.retype.focus();
      } else {
        let data = {
          id: this.user.id,
          pass: md5(this.pass.new),
        };
        await this.$http.put(
          `/pengaturan/tahun_ajaran/updatepass`,
          data,
          this.$hlp.getHeader()
        );
        this.$q.notify({
          message: "Password Berhasil Dirubah",
          color: "positive",
        });
        this.$router.go(-1);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
